import { t as template, d as delegateEvents, s as spread, i as insert, a as setAttribute } from './common/web-1e5ff29c.js';
import { c as createComponent, a as createMemo, m as mergeProps, b as createSignal, o as onCleanup, u as useContext, d as useTransition, e as on, r as runWithOwner, f as untrack, g as createRenderEffect, s as splitProps, h as createContext, i as createRoot, S as Show, j as getOwner } from './common/solid-f64e1b7e.js';

function bindEvent(target, type, handler) {
  target.addEventListener(type, handler);
  return () => target.removeEventListener(type, handler);
}

function intercept([value, setValue], get, set) {
  return [get ? () => get(value()) : value, set ? v => setValue(set(v)) : setValue];
}

function createIntegration(get, set, init, utils) {
  let ignore = false;

  const wrap = value => typeof value === 'string' ? {
    value
  } : value;

  const signal = intercept(createSignal(wrap(get()), {
    equals: (a, b) => a.value === b.value
  }), undefined, next => {
    !ignore && set(next);
    return next;
  });
  init && onCleanup(init((value = get()) => {
    ignore = true;
    signal[1](wrap(value));
    ignore = false;
  }));
  return {
    signal,
    utils
  };
}

function normalizeIntegration(integration) {
  if (!integration) {
    return {
      signal: createSignal({
        value: ""
      })
    };
  } else if (Array.isArray(integration)) {
    return {
      signal: integration
    };
  }

  return integration;
}

function pathIntegration() {
  return createIntegration(() => ({
    value: window.location.pathname + window.location.search + window.location.hash,
    state: history.state
  }), ({
    value,
    replace,
    scroll,
    state
  }) => {
    if (replace) {
      window.history.replaceState(state, "", value);
    } else {
      window.history.pushState(state, "", value);
    }

    if (scroll) {
      window.scrollTo(0, 0);
    }
  }, notify => bindEvent(window, "popstate", () => notify()), {
    go: delta => window.history.go(delta)
  });
}

const hasSchemeRegex = /^(?:[a-z0-9]+:)?\/\//i;
const trimPathRegex = /^\/+|\/+$|\s+/g;

function normalize(path) {
  const s = path.replace(trimPathRegex, "");
  return s ? s.startsWith("?") ? s : "/" + s : "";
}

function resolvePath(base, path, from) {
  if (hasSchemeRegex.test(path)) {
    return undefined;
  }

  const basePath = normalize(base);
  const fromPath = from && normalize(from);
  let result = "";

  if (!fromPath || path.charAt(0) === "/") {
    result = basePath;
  } else if (fromPath.toLowerCase().indexOf(basePath.toLowerCase()) !== 0) {
    result = basePath + fromPath;
  } else {
    result = fromPath;
  }

  return result + normalize(path) || "/";
}

function invariant(value, message) {
  if (value == null) {
    throw new Error(message);
  }

  return value;
}

function joinPaths(from, to) {
  const t = normalize(to);

  if (t) {
    const f = from.replace(/^\/+|\/*(\*.*)?$/g, "");
    return f ? `/${f}${t}` : t;
  }

  return normalize(from);
}

function extractSearchParams(url) {
  const params = {};
  url.searchParams.forEach((value, key) => {
    params[key] = value;
  });
  return params;
}

function createMatcher(path, partial) {
  const [pattern, splat] = path.split("/*", 2);
  const segments = pattern.split("/").filter(Boolean);
  const len = segments.length;
  return location => {
    const locSegments = location.split("/").filter(Boolean);
    const lenDiff = locSegments.length - len;

    if (lenDiff < 0 || lenDiff > 0 && splat === undefined && !partial) {
      return null;
    }

    const match = {
      path: len ? "" : "/",
      params: {}
    };

    for (let i = 0; i < len; i++) {
      const segment = segments[i];
      const locSegment = locSegments[i];

      if (segment[0] === ":") {
        match.params[segment.slice(1)] = locSegment;
      } else if (segment.localeCompare(locSegment, undefined, {
        sensitivity: "base"
      }) !== 0) {
        return null;
      }

      match.path += `/${locSegment}`;
    }

    if (splat) {
      match.params[splat] = lenDiff ? locSegments.slice(-lenDiff).join("/") : "";
    }

    return match;
  };
}

function scoreRoute(route) {
  const [pattern, splat] = route.pattern.split("/*", 2);
  const segments = pattern.split("/").filter(Boolean);
  return segments.reduce((score, segment) => score + (segment.startsWith(":") ? 2 : 3), segments.length - (splat === undefined ? 0 : 1));
}

function createMemoObject(fn) {
  const map = new Map();
  const owner = getOwner();
  return new Proxy({}, {
    get(_, property) {
      const memo = map.get(property) || runWithOwner(owner, () => {
        const p = createMemo(() => fn()[property]);
        map.set(property, p);
        return p;
      });
      return memo();
    }

  });
}

const MAX_REDIRECTS = 100;
const RouterContextObj = createContext();
const RouteContextObj = createContext();

const useRouter = () => invariant(useContext(RouterContextObj), "Make sure your app is wrapped in a <Router />");

const useRoute = () => useContext(RouteContextObj) || useRouter().base;

const useResolvedPath = path => {
  const route = useRoute();
  return createMemo(() => route.resolvePath(path()));
};

const useHref = to => {
  const router = useRouter();
  return createMemo(() => {
    const to_ = to();
    return to_ !== undefined ? router.renderPath(to_) : to_;
  });
};

const useNavigate = () => useRouter().navigatorFactory();

function createRoute(routeDef, base = "", fallback) {
  const {
    path: originalPath,
    component,
    data,
    children
  } = routeDef;
  const isLeaf = !children || Array.isArray(children) && !children.length;
  const path = joinPaths(base, originalPath);
  const pattern = isLeaf ? path : path.split("/*", 1)[0];
  return {
    originalPath,
    pattern,
    element: component ? () => createComponent(component, {}) : () => {
      const {
        element
      } = routeDef;
      return element === undefined && fallback ? createComponent(fallback, {}) : element;
    },
    preload: routeDef.component ? component.preload : routeDef.preload,
    data,
    matcher: createMatcher(pattern, !isLeaf)
  };
}

function createBranch(routes, index = 0) {
  return {
    routes,
    score: scoreRoute(routes[routes.length - 1]) * 10000 - index,

    matcher(location) {
      const matches = [];

      for (let i = routes.length - 1; i >= 0; i--) {
        const route = routes[i];
        const match = route.matcher(location);

        if (!match) {
          return null;
        }

        matches.unshift({ ...match,
          route
        });
      }

      return matches;
    }

  };
}

function createBranches(routeDef, base = "", fallback, stack = [], branches = []) {
  const routeDefs = Array.isArray(routeDef) ? routeDef : [routeDef];

  for (let i = 0, len = routeDefs.length; i < len; i++) {
    const def = routeDefs[i];
    const route = createRoute(def, base, fallback);
    stack.push(route);

    if (def.children) {
      createBranches(def.children, route.pattern, fallback, stack, branches);
    } else {
      const branch = createBranch([...stack], branches.length);
      branches.push(branch);
    }

    stack.pop();
  } // Stack will be empty on final return


  return stack.length ? branches : branches.sort((a, b) => b.score - a.score);
}

function getRouteMatches(branches, location) {
  for (let i = 0, len = branches.length; i < len; i++) {
    const match = branches[i].matcher(location);

    if (match) {
      return match;
    }
  }

  return [];
}

function createLocation(path, state) {
  const origin = new URL("http://sar");
  const url = createMemo(prev => {
    const path_ = path();

    try {
      return new URL(path_, origin);
    } catch (err) {
      console.error(`Invalid path ${path_}`);
      return prev;
    }
  }, origin, {
    equals: (a, b) => a.href === b.href
  });
  const pathname = createMemo(() => url().pathname);
  const search = createMemo(() => url().search.slice(1));
  const hash = createMemo(() => url().hash.slice(1));
  const key = createMemo(() => "");
  return {
    get pathname() {
      return pathname();
    },

    get search() {
      return search();
    },

    get hash() {
      return hash();
    },

    get state() {
      return state();
    },

    get key() {
      return key();
    },

    query: createMemoObject(on(search, () => extractSearchParams(url())))
  };
}

function createRouterContext(integration, base = "", data, out) {
  const {
    signal: [source, setSource],
    utils = {}
  } = normalizeIntegration(integration);
  const basePath = resolvePath("", base);
  const output =  undefined;

  if (basePath === undefined) {
    throw new Error(`${basePath} is not a valid base path`);
  } else if (basePath && !source().value) {
    setSource({
      value: basePath,
      replace: true,
      scroll: false
    });
  }

  const [isRouting, start] = useTransition();
  const [reference, setReference] = createSignal(source().value);
  const [state, setState] = createSignal(source().state);
  const location = createLocation(reference, state);
  const referrers = [];
  const baseRoute = {
    pattern: basePath,
    params: {},
    path: () => basePath,
    outlet: () => null,

    resolvePath(to) {
      return resolvePath(basePath, to);
    }

  };

  if (data) {
    baseRoute.data = data({
      params: {},
      location,
      navigate: navigatorFactory(baseRoute)
    });
  }

  function navigateFromRoute(route, to, options) {
    // Untrack in case someone navigates in an effect - don't want to track `reference` or route paths
    untrack(() => {
      if (typeof to === "number") {
        if (!to) ;else if (utils.go) {
          utils.go(to);
        } else {
          console.warn("Router integration does not support relative routing");
        }
        return;
      }

      const {
        replace,
        resolve,
        scroll,
        state: nextState
      } = {
        replace: false,
        resolve: true,
        scroll: true,
        ...options
      };
      const resolvedTo = resolve ? route.resolvePath(to) : resolvePath("", to);

      if (resolvedTo === undefined) {
        throw new Error(`Path '${to}' is not a routable path`);
      } else if (referrers.length >= MAX_REDIRECTS) {
        throw new Error("Too many redirects");
      }

      const current = reference();

      if (resolvedTo !== current || nextState !== state()) {
        {
          const len = referrers.push({
            value: current,
            replace,
            scroll,
            state
          });
          start(() => {
            setReference(resolvedTo);
            setState(nextState);
          }, () => {
            if (referrers.length === len) {
              navigateEnd({
                value: resolvedTo,
                state: nextState
              });
            }
          });
        }
      }
    });
  }

  function navigatorFactory(route) {
    // Workaround for vite issue (https://github.com/vitejs/vite/issues/3803)
    route = route || useContext(RouteContextObj) || baseRoute;
    return (to, options) => navigateFromRoute(route, to, options);
  }

  function navigateEnd(next) {
    const first = referrers[0];

    if (first) {
      if (next.value !== first.value || next.state !== first.state) {
        setSource({ ...next,
          replace: first.replace,
          scroll: first.scroll
        });
      }

      referrers.length = 0;
    }
  }

  createRenderEffect(() => {
    const {
      value,
      state
    } = source();

    if (value !== untrack(reference)) {
      start(() => {
        setReference(value);
        setState(state);
      });
    }
  });
  return {
    base: baseRoute,
    out: output,
    location,
    isRouting,
    renderPath: utils.renderPath || (path => path),
    navigatorFactory
  };
}

function createRouteContext(router, parent, child, match) {
  const {
    base,
    location,
    navigatorFactory
  } = router;
  const {
    pattern,
    element: outlet,
    preload,
    data
  } = match().route;
  const path = createMemo(() => match().path);
  const params = createMemoObject(() => match().params);
  preload && preload();
  const route = {
    parent,
    pattern,

    get child() {
      return child();
    },

    path,
    params,
    outlet,

    resolvePath(to) {
      return resolvePath(base.path(), to, path());
    }

  };

  if (data) {
    route.data = data({
      params,
      location,
      navigate: navigatorFactory(route)
    });
  }

  return route;
}

const _tmpl$ = template(`<a></a>`);

const Router = props => {
  const {
    source,
    url,
    base,
    data,
    out
  } = props;
  const integration = source || ( pathIntegration());
  const routerState = createRouterContext(integration, base, data);
  return createComponent(RouterContextObj.Provider, {
    value: routerState,

    get children() {
      return props.children;
    }

  });
};

const Routes = props => {
  const router = useRouter();
  const parentRoute = useRoute();
  const branches = createMemo(() => createBranches(props.children, joinPaths(parentRoute.pattern, props.base || ""), Outlet));
  const matches = createMemo(() => getRouteMatches(branches(), router.location.pathname));

  if (router.out) {
    router.out.matches.push(matches().map(({
      route,
      path,
      params
    }) => ({
      originalPath: route.originalPath,
      pattern: route.pattern,
      path,
      params
    })));
  }

  const disposers = [];
  let root;
  const routeStates = createMemo(on(matches, (nextMatches, prevMatches, prev) => {
    let equal = prevMatches && nextMatches.length === prevMatches.length;
    const next = [];

    for (let i = 0, len = nextMatches.length; i < len; i++) {
      const prevMatch = prevMatches && prevMatches[i];
      const nextMatch = nextMatches[i];

      if (prev && prevMatch && nextMatch.route.pattern === prevMatch.route.pattern) {
        next[i] = prev[i];
      } else {
        equal = false;

        if (disposers[i]) {
          disposers[i]();
        }

        createRoot(dispose => {
          disposers[i] = dispose;
          next[i] = createRouteContext(router, next[i - 1] || parentRoute, () => routeStates()[i + 1], () => matches()[i]);
        });
      }
    }

    disposers.splice(nextMatches.length).forEach(dispose => dispose());

    if (prev && equal) {
      return prev;
    }

    root = next[0];
    return next;
  }));
  return createComponent(Show, {
    get when() {
      return routeStates() && root;
    },

    children: route => createComponent(RouteContextObj.Provider, {
      value: route,

      get children() {
        return route.outlet();
      }

    })
  });
};

const useRoutes = (routes, base) => {
  return () => createComponent(Routes, {
    base: base,
    children: routes
  });
};

const Outlet = () => {
  const route = useRoute();
  return createComponent(Show, {
    get when() {
      return route.child;
    },

    children: child => createComponent(RouteContextObj.Provider, {
      value: child,

      get children() {
        return child.outlet();
      }

    })
  });
};

function LinkBase(props) {
  const [, rest] = splitProps(props, ["children", "to", "href", "state", "onClick"]);
  const navigate = useNavigate();
  const href = useHref(() => props.to);

  const handleClick = evt => {
    const {
      onClick,
      to,
      target
    } = props;

    if (typeof onClick === "function") {
      onClick(evt);
    } else if (onClick) {
      onClick[0](onClick[1], evt);
    }

    if (to !== undefined && !evt.defaultPrevented && evt.button === 0 && (!target || target === "_self") && !(evt.metaKey || evt.altKey || evt.ctrlKey || evt.shiftKey)) {
      evt.preventDefault();
      navigate(to, {
        resolve: false,
        replace: props.replace || false,
        scroll: !props.noScroll,
        state: props.state
      });
    }
  };

  return (() => {
    const _el$ = _tmpl$.cloneNode(true);

    _el$.$$click = handleClick;
    spread(_el$, rest, false, true);
    insert(_el$, () => props.children);
    createRenderEffect(() => setAttribute(_el$, "href", href() || props.href));
    return _el$;
  })();
}

function Link(props) {
  const to = useResolvedPath(() => props.href);
  return createComponent(LinkBase, mergeProps(props, {
    get to() {
      return to();
    }

  }));
}

delegateEvents(["click"]);

export { Link, Router, useRoutes };
