import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';
import.meta.env = __SNOWPACK_ENV__;

import { createComponent as _$createComponent } from "../_snowpack/pkg/solid-js/web.js";
import { App } from './App.js';
import { Router } from '../_snowpack/pkg/solid-app-router.js';
import { render } from '../_snowpack/pkg/solid-js/web.js';
const dispose = render(() => _$createComponent(Router, {
  get children() {
    return _$createComponent(App, {});
  }

}), document.getElementById('app'));
/**
 * Hot Module Replacement (HMR) - Remove this snippet to remove HMR.
 * Learn more: https://www.snowpack.dev/#hot-module-replacement
 *
 * Note: Solid doesn't support state preservation on hot reload as of yet
 */

if (__SNOWPACK_ENV__.MODE === 'development') {
  undefined /* [snowpack] import.meta.hot */ .accept();
  undefined /* [snowpack] import.meta.hot */ .dispose(dispose);
}