import { lazy } from '../_snowpack/pkg/solid-js.js';
export const routes = [{
  path: '/',
  component: lazy(() => import("./pages/Home.js"))
}, {
  path: '/projects',
  component: lazy(() => import("./pages/Projects.js"))
}, {
  path: '/playground',
  component: lazy(() => import("./pages/Playground.js"))
} // {
//   path: '/users/:id',
//   component: lazy(() => import('./pages/users/[id].js')),
//   children: [
//     {path: '/', component: lazy(() => import('./pages/users/[id]/index.js'))},
//     {path: '/settings', component: lazy(() => import('./pages/users/[id]/settings.js'))},
//     {path: '*all', component: lazy(() => import('./pages/users/[id]/[...all].js'))},
//   ],
// },
];